<template>
  <!-- <div class="wameed-filter-modal  "> -->
  <b-modal
    v-model="show"
    id="wameed-filter-modal"
    class="wameed-filter-modal"
    title=""
    no-fade
    scrollable
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="text-medium-20">{{ $t("filter_data") }}</h4>
        <b-button variant="white" size="sm" class="close" @click="show = false">
          <close-icon />
        </b-button>
      </div>
    </template>
    <div class="wameed-filter-modal__section">
      <!-- types -->
      <app-collapse accordion :type="collapseType">
        <app-collapse-item
          v-for="(item, i) in content"
          :key="i"
          :title="$t(item.title)"
        >
          <div class="wameed-checkbox">
            <b-form-group v-if="item.data">
              <b-form-checkbox
                v-for="(value, i) in item.data"
                :key="i"
                v-model="item.selectedData"
                :value="value[item.id]"
                class="custom-control-main my-2"
              >
                <span class="text-book-16 text-font-sub" dir="ltr">
                  {{
                    item.translate ? $t(value[item.name]) : value[item.name]
                  }}</span
                >
                <span
                  v-if="item.count"
                  class="text-book-16 text-font-secondary"
                >
                  ({{ value[item.count] }})
                </span>
              </b-form-checkbox>
            </b-form-group>
            <!-- <b-button class="px-0 text-book-16" variant="flat-main"
              >عرض المزيد....</b-button
            > -->
          </div>
        </app-collapse-item>
      </app-collapse>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <wameed-btn
          @submitAction="applyFilter()"
          variant="main"
          size="sm"
          :title="$t('filter')"
          classes="text-book-18 py-5 mx-2 applyBtn text-white "
        />
        <wameed-btn
          @submitAction="resetFilter()"
          variant="white"
          :title="$t('reset')"
          size="sm"
          classes="text-book-17 text-font-sub mx-2 "
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import WameedBtn from "./WameedBtn.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: null,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,

    // 3rd party
    VuePerfectScrollbar,
    WameedBtn,
  },
  data: () => ({
    collapseType: "margin",
  }),
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit("applyFilter");
      this.show = false;
    },
    resetFilter() {
      this.$emit("resetFilter");
      this.show = false;
    },
  },
};
</script>
 
